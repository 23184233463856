import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Dashboard.vue'
import Reservations from '../views/Reservations.vue'
import Visites from '@/views/Visites.vue'
import Login from '@/views/Login.vue'
import Residences from '@/views/Residences.vue'
import Utilisateurs from '@/views/Utilisateurs.vue'
import Gestionnaire from '@/views/Gestionnaire.vue'

// Définir les routes
const routes = [
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: Reservations
  },
  {
    path: '/visites',
    name: 'visites',
    component: Visites
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/residences',
    name: 'residences',
    component: Residences
  },
  {
    path: '/utilisateurs',
    name: 'utilisateurs',
    component: Utilisateurs
  },
  {
    path: '/gestionnaires',
    name: 'gestionnaires',
    component: Gestionnaire
  },
]

// Créer un instance de Vue Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Utiliser le mode 'history'
  routes, // Définir les routes
})

export default router
