<template>
    <div class="login">
      <h2>Connexion</h2>
      <form @submit.prevent="login">
        <input type="email" v-model="email" placeholder="Email" required />
        <input type="password" v-model="password" placeholder="Mot de passe" required />
        <button type="submit">Se connecter</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LogIn',
    data() {
      return {
        email: '',
        password: '',
      };
    },
    methods: {
      login() {
        // Logique de connexion
        this.$router.push('/dashboard');
      },
    },
  };
  </script>
  
  <style scoped>
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .login input {
    margin-bottom: 1rem;
    padding: 0.5rem;
  }
  
  .login button {
    padding: 0.5rem;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login button:hover {
    background-color: #555;
  }
  </style>