<template>
  <div :class="['dashboard', { 'dark-mode': isDarkMode }]">
    <div class="header">
      <h2>Tableau de bord</h2>
      <button @click="toggleDarkMode" class="dark-mode-toggle">
        {{ isDarkMode ? '☀️' : '🌙' }}
      </button>
    </div>
    <p>Bienvenue sur le tableau de bord de Sarezide.ci</p>

    <!-- Cartes de statistiques -->
    <div class="stats-cards">
      <div 
        class="card" 
        v-for="(stat, index) in stats" 
        :key="index" 
        :class="`card-${index + 1}`"
        @click="openStatModal(stat)"
      >
        <div class="card-icon">
          <font-awesome-icon :icon="stat.icon" />
        </div>
        <div class="card-content">
          <h3>{{ stat.title }}</h3>
          <p class="stat-value">{{ stat.value }}</p>
          <p class="stat-label">{{ stat.label }}</p>
        </div>
      </div>
    </div>

    <!-- Tableau des utilisateurs -->
    <div class="users-table">
      <h3>Utilisateurs</h3>
      <table>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Nom User</th>
            <th>Email</th>
            <th>Résidences Enregistrés</th>
            <th>Réservations Traitées</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index">
            <td>
                <img :src="user.photo || 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png?20200919003010'" :alt="user.nom" class="user-avatar">
              </td>
            <td>{{ user.nom }} {{ user.prenom }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.nombre_residences }}</td>
            <td>0</td>
            <td>
              <button class="action-button" @click="viewUser(user)">Voir</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal pour les détails de l'utilisateur -->
    <transition name="modal">
      <div v-if="showUserModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content user-modal" @click.stop>
          <div class="user-header">
            <img :src="selectedUser.photo" :alt="selectedUser.name" class="user-photo">
            <div class="user-info">
              <h3>{{ selectedUser.name }}</h3>
              <p class="user-code">Code: {{ selectedUser.code }}</p>
            </div>
          </div>
          <div class="user-tabs">
            <button 
              :class="['tab-button', { active: activeTab === 'residences' }]" 
              @click="activeTab = 'residences'"
            >
              Résidences
            </button>
            <button 
              :class="['tab-button', { active: activeTab === 'reservations' }]" 
              @click="activeTab = 'reservations'"
            >
              Réservations
            </button>
          </div>
          <div v-if="activeTab === 'residences'" class="tab-content">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Libellé</th>
                  <th>Propriétaire</th>
                  <th>Contact</th>
                  <th>Commune</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="residence in usersResid" :key="residence.id_resid">
                  <td><img :src="residence.image" :alt="residence.libelle" class="residence-image"></td>
                  <td>{{ residence.libelle_resid }}</td>
                  <td>{{ residence.user }}</td>
                  <td>{{ residence.contact }}</td>
                  <td>{{ residence.commune_resid }}</td>
                  <td><button class="action-button">Voir</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="activeTab === 'reservations'" class="tab-content">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Résidence</th>
                  <th>Client</th>
                  <th>Contact</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="reservation in selectedUser.reservations" :key="reservation.id">
                  <td><img :src="reservation.image" :alt="reservation.residence" class="residence-image"></td>
                  <td>{{ reservation.residence }}</td>
                  <td>{{ reservation.client }}</td>
                  <td>{{ reservation.contact }}</td>
                  <td><button class="action-button">Voir</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>

    <!-- Modal pour les statistiques détaillées -->
    <transition name="modal">
      <div v-if="showStatModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content stat-modal" @click.stop>
          <h3>{{ selectedStat.title }} - Statistiques détaillées</h3>
          <div class="stat-grid">
            <div class="stat-item">
              <h4>Total</h4>
              <p>{{ selectedStat.detailedStats.total }}</p>
            </div>
            <div class="stat-item">
              <h4>Ce mois</h4>
              <p>{{ selectedStat.detailedStats.month }}</p>
            </div>
            <div class="stat-item">
              <h4>Cette semaine</h4>
              <p>{{ selectedStat.detailedStats.week }}</p>
            </div>
            <div class="stat-item">
              <h4>Aujourd'hui</h4>
              <p>{{ selectedStat.detailedStats.day }}</p>
            </div>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faCalendarAlt, faEye, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faHome, faCalendarAlt, faEye, faUsers);

export default {
  name: 'DashBoard',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      isDarkMode: false,
      showUserModal: false,
      showStatModal: false,
      selectedUser: null,
      selectedStat: null,
      usersResid: null,
      activeTab: 'residences',
      stats: [
        { 
          title: 'Résidences', 
          value: '1,234', 
          label: 'Total', 
          icon: ['fas', 'home'],
          detailedStats: {
            total: '1,234',
            month: '89',
            week: '23',
            day: '5'
          }
        },
        { 
          title: 'Réservations', 
          value: '567', 
          label: 'Cette semaine', 
          icon: ['fas', 'calendar-alt'],
          detailedStats: {
            total: '5,678',
            month: '567',
            week: '123',
            day: '18'
          }
        },
        { 
          title: 'Visites', 
          value: '89', 
          label: 'Aujourd\'hui', 
          icon: ['fas', 'eye'],
          detailedStats: {
            total: '10,234',
            month: '1,234',
            week: '345',
            day: '89'
          }
        },
        { 
          title: 'Utilisateurs', 
          value: '45', 
          label: 'Actifs', 
          icon: ['fas', 'users'],
          detailedStats: {
            total: '2,345',
            month: '234',
            week: '78',
            day: '45'
          }
        },
      ],
      users: null,
    };
  },
  methods: {
    async fetchUsers() {
        try {
          const response = await axios.get('https://korezide.com/appi_web/admin_user_sarezide.php');
          if (response.data.status === 'success') {
            this.users = response.data.data;
            this.updateStats();
          } else {
            console.error('Erreur:', response.data.message);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
      },

      async fetchUsersId(id) {
        this.usersResid = null;
        try {
          const response = await axios.get('https://korezide.com/appi_web/admin_user_sarezide.php?id='+id);
          if (response.data.status === 'success') {
            this.usersResid = response.data.data;
            this.updateStats();
          } else {
            console.error('Erreur:', response.data.message);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
      },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    viewUser(user) {
      this.fetchUsersId(user.id_user);
      this.selectedUser = user;
      this.showUserModal = true;
    },
    openStatModal(stat) {
      this.selectedStat = stat;
      this.showStatModal = true;
    },
    closeModal() {
      this.showUserModal = false;
      this.showStatModal = false;
      this.selectedUser = null;
      this.selectedStat = null;
    },
  },
  mounted() {
      this.fetchUsers();
    }
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode {
  background-color: #1a1a1a;
  color: #f5f5f5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.dark-mode h2 {
  color: #f5f5f5;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.dark-mode p {
  color: #ccc;
}

/* Cartes de statistiques */
.stats-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dark-mode .card {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dark-mode .card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.card-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 15px;
}

.card-content h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.dark-mode .card-content h3 {
  color: #f5f5f5;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 14px;
  color: #666;
}

.dark-mode .stat-label {
  color: #ccc;
}

/* Tableau des utilisateurs */
.users-table {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.dark-mode .users-table {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.users-table h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
}

.dark-mode .users-table h3 {
  color: #f5f5f5;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode th, .dark-mode td {
  border-bottom: 1px solid #444;
}

th {
  background-color: #007bff;
  color: white;
}

.dark-mode th {
  background-color: #005cbf;
}

tr:hover {
  background-color: #f1f1f1;
}

.dark-mode tr:hover {
  background-color: #333;
}

.action-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

.user-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.dark-mode .modal-content {
  background-color: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.modal-content h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.dark-mode .modal-content h3 {
  color: #f5f5f5;
}

.modal-content p {
  margin-bottom: 10px;
}

.close-modal {
  margin-top: 15px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-modal:hover {
  background-color: #0056b3;
}

/* Modal transition */
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  animation: fadeIn 0.5s ease forwards;
}

.card:nth-child(1) { animation-delay: 0.1s; }
.card:nth-child(2) { animation-delay: 0.2s; }
.card:nth-child(3) { animation-delay: 0.3s; }
.card:nth-child(4) { animation-delay: 0.4s; }

/* Styles pour le modal des statistiques détaillées */
.stat-modal {
  max-width: 500px;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.dark-mode .stat-item {
  background-color: #333;
}

.stat-item h4 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

.dark-mode .stat-item h4 {
  color: #f5f5f5;
}

.stat-item p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

/* Styles pour le modal des détails de l'utilisateur */
.user-modal {
  max-width: 800px;
  width: 90%;
}

.user-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.user-info h3 {
  margin: 0;
  font-size: 24px;
}

.user-code {
  color: #666;
  font-size: 14px;
}

.dark-mode .user-code {
  color: #ccc;
}

.user-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dark-mode .tab-button {
  background-color: #333;
  color: #f5f5f5;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.dark-mode .tab-button.active {
  background-color: #005cbf;
}

.tab-content {
  max-height: 400px;
  overflow-y: auto;
}

.residence-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

/* Ajustements pour le tableau dans le modal */
.user-modal table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.user-modal th,
.user-modal td {
  padding: 12px;
  text-align: left;
  border: none;
}

.user-modal th {
  background-color: #f1f1f1;
  color: #333;
}

.dark-mode .user-modal th {
  background-color: #333;
  color: #f5f5f5;
}

.user-modal tr {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dark-mode .user-modal tr {
  background-color: #2a2a2a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.user-modal tr:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.dark-mode .user-modal tr:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
</style>