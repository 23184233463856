<template>
    <div :class="['gestionnaires', { 'dark-mode': isDarkMode }]">
      <div class="header">
        <h2>Gestionnaires</h2>
        <button @click="toggleDarkMode" class="dark-mode-toggle">
          {{ isDarkMode ? '☀️' : '🌙' }}
        </button>
        <button @click="openAddGestionnaireModal" class="add-button">Ajouter un gestionnaire</button>
      </div>
  
      <!-- Tableau des gestionnaires -->
      <div class="gestionnaires-table">
        <table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Ville</th>
              <th>Commune</th>
              <th>Contact 1</th>
              <th>Contact 2</th>
              <th>Tarif Min</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="gestionnaire in gestionnaires" :key="gestionnaire.id">
              <td>{{ gestionnaire.nom }}</td>
              <td>{{ gestionnaire.ville }}</td>
              <td>{{ gestionnaire.commune }}</td>
              <td>{{ gestionnaire.contact1 }}</td>
              <td>{{ gestionnaire.contact2 }}</td>
              <td>{{ gestionnaire.tarifMin }}</td>
              <td>
                <button class="action-button" @click="editGestionnaire(gestionnaire)">Modifier</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Modal pour ajouter/modifier un gestionnaire -->
      <transition name="modal">
        <div v-if="showGestionnaireModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content" @click.stop>
            <div v-if="errorMessage" class="error-banner">{{ errorMessage }}</div>
            <h3>{{ isEditing ? 'Modifier' : 'Ajouter' }} un gestionnaire</h3>
            <form @submit.prevent="saveGestionnaire">
              <div class="form-group">
                <label for="nom">Nom</label>
                <input type="text" id="nom" v-model="form.nom" required>
              </div>
              <div class="form-group">
                <label for="ville">Ville</label>
                <input type="text" id="ville" v-model="form.ville" required>
              </div>
              <div class="form-group">
                <label for="commune">Commune</label>
                <input type="text" id="commune" v-model="form.commune" required>
              </div>
              <div class="form-group">
                <label for="contact1">Contact 1</label>
                <input 
                  type="text" 
                  id="contact1" 
                  v-model="form.contact1" 
                  @input="checkContactExistence"
                  required
                >
              </div>
              <div class="form-group">
                <label for="contact2">Contact 2</label>
                <input type="text" id="contact2" v-model="form.contact2">
              </div>
              <div class="form-group">
                <label for="tarifMin">Tarif Min</label>
                <input type="number" id="tarifMin" v-model="form.tarifMin" required>
              </div>
              <div class="action-buttons">
                <button type="submit" class="action-button" :disabled="!!errorMessage">
                  {{ isEditing ? 'Modifier' : 'Ajouter' }}
                </button>
                <button type="button" @click="closeModal" class="close-modal">Annuler</button>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'GesTionnaires',
    data() {
      return {
        isDarkMode: false,
        showGestionnaireModal: false,
        isEditing: false,
        gestionnaires: [],
        form: {
          id: null,
          nom: '',
          ville: '',
          commune: '',
          contact1: '',
          contact2: '',
          tarifMin: 0
        },
        errorMessage: ''
      };
    },
    methods: {
      async fetchGestionnaires() {
        try {
          const response = await axios.get('https://korezide.com/appi_web/gestionnaire.php');
          this.gestionnaires = response.data.data || [];
        } catch (error) {
          console.error("Erreur lors de la récupération des gestionnaires:", error);
        }
      },
      async addGestionnaire(gestionnaire) {
        try {
          const response = await axios.post('https://korezide.com/appi_web/gestionnaire.php', gestionnaire);
          if (response.data.status === 'success') {
            await this.fetchGestionnaires();
            this.closeModal();
          } else {
            this.errorMessage = response.data.message || "Erreur lors de l'ajout du gestionnaire";
          }
        } catch (error) {
          console.error("Erreur lors de l'ajout du gestionnaire:", error);
          this.errorMessage = "Erreur lors de l'ajout du gestionnaire";
        }
      },
      async updateGestionnaire(gestionnaire) {
        try {
          const response = await axios.put('https://korezide.com/appi_web/gestionnaire.php', gestionnaire);
          if (response.data.status === 'success') {
            await this.fetchGestionnaires();
            this.closeModal();
          } else {
            this.errorMessage = response.data.message || "Erreur lors de la modification du gestionnaire";
          }
        } catch (error) {
          console.error("Erreur lors de la modification du gestionnaire:", error);
          this.errorMessage = "Erreur lors de la modification du gestionnaire";
        }
      },
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;
      },
      openAddGestionnaireModal() {
        this.isEditing = false;
        this.resetForm();
        this.showGestionnaireModal = true;
      },
      editGestionnaire(gestionnaire) {
        this.isEditing = true;
        this.form = { ...gestionnaire };
        this.showGestionnaireModal = true;
      },
      checkContactExistence() {
        if (this.form.contact1 && !this.isEditing) {
          const existingGestionnaire = this.gestionnaires.find(g => g.contact1 === this.form.contact1);
          if (existingGestionnaire) {
            this.errorMessage = `Ce numéro de contact existe déjà pour ${existingGestionnaire.commune}`;
          } else {
            this.errorMessage = '';
          }
        } else {
          this.errorMessage = '';
        }
      },
      async saveGestionnaire() {
        if (this.errorMessage) {
          return; // Empêcher la sauvegarde si une erreur est présente
        }
        
        if (this.isEditing) {
          await this.updateGestionnaire(this.form);
        } else {
          await this.addGestionnaire(this.form);
        }
      },
      resetForm() {
        this.form = {
          id: null,
          nom: '',
          ville: '',
          commune: '',
          contact1: '',
          contact2: '',
          tarifMin: 0
        };
        this.errorMessage = '';
      },
      closeModal() {
        this.showGestionnaireModal = false;
        this.resetForm();
      }
    },
    mounted() {
      this.fetchGestionnaires();
    }
  };
  </script>
  
  <style scoped>
  .gestionnaires {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode {
    background-color: #1a1a1a;
    color: #f5f5f5;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dark-mode-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .dark-mode-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .add-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .gestionnaires-table {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow-x: auto;
  }
  
  .dark-mode .gestionnaires-table {
    background: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode th, .dark-mode td {
    border-bottom: 1px solid #444;
  }
  
  th {
    background-color: #007bff;
    color: white;
  }
  
  .dark-mode th {
    background-color: #005cbf;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  .dark-mode tr:hover {
    background-color: #333;
  }
  
  .action-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  
  .action-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    transition: background-color 0.3s ease;
    position: relative;
    padding-top: 20px;
  }
  
  .dark-mode .modal-content {
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .dark-mode .modal-content h3 {
    color: #f5f5f5;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .dark-mode .form-group input {
    border-color: #444;
    background-color: #333;
    color: #f5f5f5;
  }
  
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .action-buttons button {
    margin-left: 10px;
  }
  
  .close-modal {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-modal:hover {
    background-color: #5a6268;
  }
  
  .error-banner {
    background-color: #ffcccc;
    color: #cc0000;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  /* Modal transition */
  .modal-enter-active, .modal-leave-active {
    transition: opacity 0.3s;
  }
  
  .modal-enter, .modal-leave-to {
    opacity: 0;
  }
  </style>