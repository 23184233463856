<template>
    <aside class="sidebar">
      <div class="sidebar-header">
        <h2 class="sidebar-title">{{ entrepriseName }}</h2>
      </div>
      <nav class="sidebar-nav">
        <ul>
          <li v-for="(item, index) in menuItems" :key="index">
            <router-link :to="item.route" :class="{ 'financial': item.financial }">
              <div class="icon-container">
                <font-awesome-icon :icon="item.icon" size="lg" />
              </div>
              <span class="menu-text">{{ item.text }}</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </aside>
  </template>
  
  <script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { faShoppingCart, faBox, faUsers, faTruck, faChartLine, faWallet, faUserCog } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  
  library.add(faShoppingCart, faBox, faUsers, faTruck, faChartLine, faWallet, faUserCog);
  
  export default {
    name: 'SideBar',
    components: {
      FontAwesomeIcon,
    },
    data() {
      return {
        entrepriseName: 'Sarezide.ci', // Remplacez par le nom de l'entreprise
        menuItems: [
          { text: 'Dashboard', icon: ['fas', 'shopping-cart'], route: '/dashboard' },
          { text: 'Réservations', icon: ['fas', 'box'], route: '/reservations' },
          { text: 'Résidences', icon: ['fas', 'users'], route: '/residences' },
          { text: 'Gestionnaire', icon: ['fas', 'truck'], route: '/gestionnaires', financial: true },
          { text: 'Visites', icon: ['fas', 'truck'], route: '/visites', financial: true },
          { text: 'Utilisateurs', icon: ['fas', 'truck'], route: '/utilisateurs', financial: true },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .sidebar {
    width: 250px;
    height: 100vh;
    background: linear-gradient(45deg, #2c3e50, #34495e);
    color: white;
    position: fixed;
    left: 0;
    top: 60px; /* Hauteur de la Navbar */
    bottom: 0;
    overflow-y: auto;
    z-index: 999;
  }
  
  .sidebar-header {
    padding: 1.5rem;
    text-align: center;
  }
  
  .sidebar-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .sidebar-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar-nav li {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .sidebar-nav li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 0.5rem;
  }
  
  .icon-container {
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .sidebar-nav li a:hover .icon-container {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-5px);
  }
  
  .sidebar-nav li a:hover .icon-container svg {
    transform: scale(1.1);
  }
  
  .menu-text {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .sidebar-nav li a:hover .menu-text {
    opacity: 1;
  }
  
  /* Style spécial pour la gestion financière */
  .sidebar-nav li a.financial .icon-container {
    background-color: rgba(255, 215, 0, 0.2); /* Couleur dorée */
  }
  
  .sidebar-nav li a.financial:hover .icon-container {
    background-color: rgba(255, 215, 0, 0.3);
  }
  </style>