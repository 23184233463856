<template>
    <div :class="['residences', { 'dark-mode': isDarkMode }]">
      <div class="header">
        <h2>Résidences</h2>
        <button @click="toggleDarkMode" class="dark-mode-toggle">
          {{ isDarkMode ? '☀️' : '🌙' }}
        </button>
      </div>
  
      <!-- Zone de statistiques -->
      <div class="stats-container">
        <div v-for="stat in stats" :key="stat.label" class="stat-card">
          <div class="stat-icon" :style="{ backgroundColor: stat.color }">
            <i :class="stat.icon"></i>
          </div>
          <div class="stat-details">
            <h3>{{ stat.value }}</h3>
            <p>{{ stat.label }}</p>
          </div>
        </div>
      </div>
  
      <!-- Filtres et recherche -->
      <div class="filter-buttons">
        <button
          v-for="filter in filters"
          :key="filter.value"
          :class="['filter-button', { active: currentFilter === filter.value }]"
          @click="setFilter(filter.value)"
        >
          {{ filter.label }}
        </button>
      </div>
  
      <div class="search-container">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Rechercher par libellé, ville, commune ou service..."
          class="search-input"
        />
      </div>
  
      <!-- Tableau des résidences -->
      <div class="residences-table">
        <table>
          <thead>
            <tr>
              <th>N°</th>
              <th>Image</th>
              <th>Libellé</th>
              <th>Tarif</th>
              <th>Ville</th>
              <th>Commune</th>
              <th>Services</th>
              <th>Propriétaire</th>
              <th>Statut</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(residence, index) in paginatedResidences" :key="residence.id_resid">
              <td>{{ index + 1 }}</td>
              <td>
                <img
                  :src="residence.images[0].lien || '/default-image.jpg'"
                  :alt="residence.residence.libelle"
                  class="residence-image"
                />
              </td>
              <td>{{ residence.residence.libelle }}</td>
              <td>{{ formatPrice(residence.residence.prix_nuit) }}</td>
              <td>{{ residence.residence.ville_resid }}</td>
              <td>{{ residence.residence.commune_resid }}</td>
              <td>
                <ul class="services-list">
                  <li v-for="service in residence.services_icon" :key="service">{{ service }}</li>
                </ul>
              </td>
              <td>
                <a href="#" @click.prevent="voirHote(residence.hote)">
                  {{ residence.hote.nom }} {{ residence.hote.prenom }}
                </a>
              </td>
              <td>
                <span :class="['status-badge', getStatusClass(residence.residence.etat)]">
                  {{ getStatusLabel(residence.residence.etat) }}
                </span>
              </td>
              <td>
                <button class="action-button" @click="voirResidence(residence)">Voir</button>
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Pagination -->
        <div class="pagination">
          <button
            :disabled="currentPage === 1"
            @click="setPage(currentPage - 1)"
            class="page-button"
          >
            Précédent
          </button>
          <button
            v-for="page in totalPages"
            :key="page"
            :class="['page-button', { active: currentPage === page }]"
            @click="setPage(page)"
          >
            {{ page }}
          </button>
          <button
            :disabled="currentPage === totalPages"
            @click="setPage(currentPage + 1)"
            class="page-button"
          >
            Suivant
          </button>
        </div>
      </div>
  
      <!-- Modal pour les détails de la résidence -->
      <transition name="modal">
        <div v-if="showResidenceModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content residence-modal" @click.stop>
            <h3>{{ selectedResidence.residence.libelle }}</h3>
            <div class="action-buttons">
              <button
                class="action-button validate"
                @click="validateResidence"
                :disabled="selectedResidence.residence.etat === 2"
              >
                Valider
              </button>
              <button
                class="action-button refuse"
                @click="refuseResidence"
                :disabled="selectedResidence.residence.etat === 3"
              >
                Refuser
              </button>
            </div>
            <div class="webview-container">
              <iframe :src="'https://sarezide.ci/detail/' + selectedResidence.residence.id_resid" frameborder="0"></iframe>
            </div>
            <button @click="closeModal" class="close-modal">Fermer</button>
          </div>
        </div>
      </transition>
  
      <!-- Modal pour les informations de l'hôte -->
      <transition name="modal">
        <div v-if="showHoteModal" class="modal-overlay" @click="closeHoteModal">
          <div class="modal-contentt hote-modal" @click.stop>
            <h3>Informations du propriétaire</h3>
            <div v-if="selectedHote">
              <p><strong>Nom :</strong> {{ selectedHote.nom }}</p>
              <p><strong>Email :</strong> {{ selectedHote.email }}</p>
              <p><strong>Contact 1 :</strong> {{ selectedHote.contact1 }}</p>
              <p><strong>Contact 2 :</strong> {{ selectedHote.contact2 }}</p>
            </div>
            <button @click="closeHoteModal" class="close-modal">Fermer</button>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ResiDences',
    data() {
      return {
        isDarkMode: false,
        showResidenceModal: false,
        showHoteModal: false,
        selectedResidence: null,
        selectedHote: null,
        currentFilter: 'tous',
        searchQuery: '',
        currentPage: 1,
        itemsPerPage: 10,
        filters: [
          { label: 'Tous', value: 'tous' },
          { label: 'En attente', value: 'en_attente' },
          { label: 'Validés', value: 'valide' },
          { label: 'Refusés', value: 'refuse' },
          { label: 'Bloqués', value: 'bloque' },
        ],
        stats: [
          { label: 'Total Résidences', value: 0, icon: 'fas fa-home', color: '#007bff' },
          { label: 'En attente', value: 0, icon: 'fas fa-clock', color: '#ffc107' },
          { label: 'Validées', value: 0, icon: 'fas fa-check-circle', color: '#28a745' },
          { label: 'Refusées', value: 0, icon: 'fas fa-times-circle', color: '#dc3545' },
        ],
        residences: [], // Les résidences seront chargées depuis l'API
      };
    },
    computed: {
      filteredResidences() {
        let filtered = this.residences;
        if (this.currentFilter !== 'tous') {
          filtered = filtered.filter((residence) => {
            switch (this.currentFilter) {
              case 'en_attente':
                return residence.residence.etat === 1;
              case 'valide':
                return residence.residence.etat === 2;
              case 'refuse':
                return residence.residence.etat === 3;
              case 'bloque':
                return residence.residence.statut_resid === 'KO';
              default:
                return true;
            }
          });
        }
        if (this.searchQuery) {
          const queries = this.searchQuery
            .toLowerCase()
            .split(',')
            .map((query) => query.trim());
          filtered = filtered.filter((residence) =>
            queries.every((query) =>
              residence.residence.libelle.toLowerCase().includes(query) ||
              residence.residence.ville_resid.toLowerCase().includes(query) ||
              residence.residence.commune_resid.toLowerCase().includes(query) ||
              residence.services_icon.some((service) => service.toLowerCase().includes(query))
            )
          );
        }
        return filtered;
      },
      paginatedResidences() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.filteredResidences.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.filteredResidences.length / this.itemsPerPage);
      }
    },
    methods: {
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;
      },
      setFilter(filter) {
    this.currentFilter = filter; // Mettre à jour le filtre actuel
    this.fetchResidences(filter); // Charger les données filtrées depuis le serveur
    },
      async fetchResidences(filter) {
        try {
            const response = await axios.get(`https://korezide.com/appi_web/admin_residences.php?filter=${filter}`);
            if (response.data.status === 'success') {
            this.residences = response.data.data; // Mettre à jour les données
            this.currentPage = 1; // Réinitialiser la pagination
            this.updateStats(); // Mettre à jour les statistiques
            } else {
            console.error('Erreur:', response.data.message);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des résidences:', error);
        }
        },
      updateStats() {
        this.stats[0].value = this.residences.length;
        this.stats[1].value = this.residences.filter((residence) => residence.residence.etat === 1).length;
        this.stats[2].value = this.residences.filter((residence) => residence.residence.etat === 2).length;
        this.stats[3].value = this.residences.filter((residence) => residence.residence.etat === 3).length;
      },
      getStatusClass(etat) {
        switch (etat) {
          case 1:
            return 'en_attente';
          case 2:
            return 'en_ligne';
          case 3:
            return 'bloque';
          default:
            return 'bloque';
        }
      },
      getStatusLabel(etat) {
        const etatInt = parseInt(etat, 10); // Convertir etat en entier
        switch (etatInt) {
          case 1:
            return 'En attente';
          case 2:
            return 'enLigne';
          case 3:
            return 'Bloqué';
          default:
            return 'Inconnu';
        }
      },
      voirResidence(residence) {
        this.selectedResidence = residence;
        this.showResidenceModal = true;
      },
      closeModal() {
        this.showResidenceModal = false;
        this.selectedResidence = null;
      },
      voirHote(hote) {
        this.selectedHote = hote;
        this.showHoteModal = true;
      },
      closeHoteModal() {
        this.showHoteModal = false;
        this.selectedHote = null;
      },
      validateResidence() {
        if (this.selectedResidence) {
          this.selectedResidence.residence.etat = 2;
          // Envoyer la mise à jour au backend
        }
      },
      refuseResidence() {
        if (this.selectedResidence) {
          this.selectedResidence.residence.etat = 3;
          // Envoyer la mise à jour au backend
        }
      },
      formatPrice(price) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(price);
      },
      setPage(page) {
        if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
        }
      }
    },
    mounted() {
      this.fetchResidences(this.currentFilter);
    },
  };
  </script>
  
  
  <style scoped>

.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  cursor: pointer;
  border-radius: 5px;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
  .residences {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode {
    background-color: #1a1a1a;
    color: #f5f5f5;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dark-mode-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .dark-mode-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .dark-mode h2 {
    color: #f5f5f5;
  }
  
  /* Styles pour la zone de statistiques */
  .stats-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .stat-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: calc(25% - 15px);
  }
  
  .dark-mode .stat-card {
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .stat-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  
  .stat-icon i {
    color: white;
    font-size: 24px;
  }
  
  .stat-details {
    flex-grow: 1;
  }
  
  .stat-details h3 {
    font-size: 24px;
    margin: 0;
    color: #333;
  }
  
  .dark-mode .stat-details h3 {
    color: #f5f5f5;
  }
  
  .stat-details p {
    margin: 5px 0 0;
    color: #666;
    font-size: 14px;
  }
  
  .dark-mode .stat-details p {
    color: #aaa;
  }
  
  .filter-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #e0e0e0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode .filter-button {
    background-color: #333;
    color: #f5f5f5;
  }
  
  .filter-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .dark-mode .filter-button.active {
    background-color: #0056b3;
  }
  
  .residences-table {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow-x: auto;
  }
  
  .dark-mode .residences-table {
    background: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode th, .dark-mode td {
    border-bottom: 1px solid #444;
  }
  
  th {
    background-color: #007bff;
    color: white;
  }
  
  .dark-mode th {
    background-color: #005cbf;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  .dark-mode tr:hover {
    background-color: #333;
  }
  
  .residence-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .services-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .services-list li {
    display: inline-block;
    background-color: #e0e0e0;
    color: #333;
    padding: 2px 5px;
    margin: 2px;
    border-radius: 3px;
    font-size: 12px;
  }
  
  .dark-mode .services-list li {
    background-color: #444;
    color: #f5f5f5;
  }
  
  .status-badge {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .status-badge.en_attente {
    background-color: #ffc107;
    color: #333;
  }
  
  .status-badge.validé {
    background-color: #28a745;
    color: white;
  }
  
  .status-badge.refusé {
    background-color: #dc3545;
    color: white;
  }
  
  .action-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  
  .action-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  /* Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1400px;
    max-height: 90vh;
    overflow-y: auto;
    transition: background-color 0.3s ease;
  }

  .modal-contentt {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    transition: background-color 0.3s ease;
  }
  
  .dark-mode .modal-content {
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .dark-mode .modal-content h3 {
    color: #f5f5f5;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .action-buttons .action-button {
    margin: 0 10px;
  }
  
  .action-button.validate {
    background-color: #28a745;
  }
  
  .action-button.validate:hover {
    background-color: #218838;
  }
  
  .action-button.refuse {
    background-color: #dc3545;
  }
  
  .action-button.refuse:hover {
    background-color: #c82333;
  }
  
  .webview-container {
    width: 100%;
    height: 500px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .dark-mode .webview-container {
    border-color: #444;
  }
  
  .webview-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .close-modal {
    margin-top: 15px;
    padding: 8px 15px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-modal:hover {
    background-color: #5a6268;
  }
  
  /* Modal transition */
  .modal-enter-active, .modal-leave-active {
    transition: opacity 0.3s;
  }
  
  .modal-enter, .modal-leave-to {
    opacity: 0;
  }

  .filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  background-color: #e0e0e0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.filter-button.active {
  background-color: #007bff;
  color: white;
}

.filter-button:hover {
  background-color: #007bff;
  color: white;
}

/* Zone de recherche */
.search-container {
  width: 100%;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Mode sombre */
.dark-mode .filter-button {
  background-color: #333;
  color: #f5f5f5;
}

.dark-mode .filter-button.active {
  background-color: #0056b3;
}

.dark-mode .search-input {
  background-color: #2a2a2a;
  border-color: #444;
  color: #f5f5f5;
}
  </style>