<template>
  <div :class="['visites', { 'dark-mode': isDarkMode }]">
    <div class="header">
      <h2>Visites</h2>
      <button @click="toggleDarkMode" class="dark-mode-toggle">
        {{ isDarkMode ? '☀️' : '🌙' }}
      </button>
    </div>

    <!-- Cartes de statistiques -->
    <div class="stats-cards">
      <div 
        class="card" 
        v-for="(stat, index) in stats" 
        :key="index" 
        :class="`card-${index + 1}`"
        @click="openStatModal(stat)"
      >
        <div class="card-icon">
          <font-awesome-icon :icon="stat.icon" />
        </div>
        <div class="card-content">
          <h3>{{ stat.title }}</h3>
          <p class="stat-value">{{ stat.value }}</p>
          <p class="stat-label">{{ stat.label }}</p>
        </div>
      </div>
    </div>

    <!-- Zone de recherche -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Rechercher une visite..."
        class="search-input"
      />
    </div>

    <!-- Tableau des visites -->
    <div class="visites-table">
      <div v-if="isLoading" class="loading">Chargement en cours...</div>
      <div v-if="error" class="error">{{ error }}</div>
      <table v-if="!isLoading && !error">
        <thead>
          <tr>
            <th>N°</th>
            <th>IP</th>
            <th>Pays</th>
            <th>Ville</th>
            <th>Période</th>
            <th>Code User</th>
            <th>Page visitée</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(visite, index) in paginatedVisites" :key="visite.id_visite">
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ visite.ip }}</td>
            <td>{{ visite.pays }}</td>
            <td>{{ visite.ville }}</td>
            <td>{{ visite.periode }}</td>
            <td>{{ visite.code_user }}</td>
            <td>{{ visite.page }}</td>
            <td>
              <button class="action-button" @click="voirVisite(visite)">Voir</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="pagination" v-if="!isLoading && !error">
      <button 
        @click="prevPage" 
        :disabled="currentPage === 1"
        class="pagination-button"
      >
        Précédent
      </button>
      <span>Page {{ currentPage }} sur {{ totalPages }}</span>
      <button 
        @click="nextPage" 
        :disabled="currentPage === totalPages"
        class="pagination-button"
      >
        Suivant
      </button>
    </div>

    <!-- Modal pour les statistiques détaillées -->
    <transition name="modal">
      <div v-if="showStatModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content stat-modal" @click.stop>
          <h3>{{ selectedStat.title }} - Statistiques détaillées</h3>
          <div class="stat-grid">
            <div class="stat-item">
              <h4>Total</h4>
              <p>{{ selectedStat.detailedStats.total }}</p>
            </div>
            <div class="stat-item">
              <h4>Ce mois</h4>
              <p>{{ selectedStat.detailedStats.month }}</p>
            </div>
            <div class="stat-item">
              <h4>Cette semaine</h4>
              <p>{{ selectedStat.detailedStats.week }}</p>
            </div>
            <div class="stat-item">
              <h4>Aujourd'hui</h4>
              <p>{{ selectedStat.detailedStats.day }}</p>
            </div>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>

    <!-- Modal pour les détails de la visite -->
    <transition name="modal">
      <div v-if="showVisiteModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content visite-modal" @click.stop>
          <h3>Détails de la visite</h3>
          <div class="visite-details">
            <p><strong>IP:</strong> {{ selectedVisite.ip }}</p>
            <p><strong>Pays:</strong> {{ selectedVisite.pays }}</p>
            <p><strong>Ville:</strong> {{ selectedVisite.ville }}</p>
            <p><strong>Code User:</strong> {{ selectedVisite.code_user }}</p>
          </div>
          <h4>Historique des visites de l'utilisateur</h4>
          <div class="user-visits-table">
            <table>
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Page</th>
                  <th>IP</th>
                  <th>Période</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(visite, index) in getUserVisits(selectedVisite.code_user)" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ visite.page }}</td>
                  <td>{{ visite.ip }}</td>
                  <td>{{ visite.periode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe, faUsers, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

library.add(faGlobe, faUsers, faMapMarkerAlt, faClock);

export default {
  name: 'VisiTes',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      isDarkMode: false,
      showStatModal: false,
      showVisiteModal: false,
      selectedStat: null,
      selectedVisite: null,
      stats: [
        { 
          title: 'Total des visites', 
          value: 0, 
          label: 'Visites', 
          icon: ['fas', 'globe'],
          detailedStats: {
            total: 0,
            month: 0,
            week: 0,
            day: 0
          }
        },
        { 
          title: 'Visiteurs uniques', 
          value: 0, 
          label: 'Visiteurs', 
          icon: ['fas', 'users'],
          detailedStats: {
            total: 0,
            month: 0,
            week: 0,
            day: 0
          }
        },
        { 
          title: 'Pays visités', 
          value: 0, 
          label: 'Pays', 
          icon: ['fas', 'map-marker-alt'],
          detailedStats: {
            total: 0,
            month: 0,
            week: 0,
            day: 0
          }
        },
        { 
          title: 'Temps moyen', 
          value: '0:00', 
          label: 'Minutes', 
          icon: ['fas', 'clock'],
          detailedStats: {
            total: '0:00',
            month: '0:00',
            week: '0:00',
            day: '0:00'
          }
        },
      ],
      visites: [],
      isLoading: true,
      error: null,
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
    };
  },
  computed: {
    filteredVisites() {
      return this.visites.filter(visite => {
        return (
          visite.ip.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          visite.pays.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          visite.ville.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          visite.code_user.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          visite.page.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedVisites() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredVisites.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredVisites.length / this.perPage);
    },
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    openStatModal(stat) {
      this.selectedStat = stat;
      this.showStatModal = true;
    },
    voirVisite(visite) {
      this.selectedVisite = visite;
      this.showVisiteModal = true;
    },
    closeModal() {
      this.showStatModal = false;
      this.showVisiteModal = false;
      this.selectedStat = null;
      this.selectedVisite = null;
    },
    getUserVisits(code_user) {
      return this.visites.filter(visite => visite.code_user === code_user);
    },
    async fetchVisites() {
      try {
        const response = await axios.get('https://korezide.com/appi_web/admin_visiteurs.php');
        this.visites = response.data.data;
        this.updateStats();
        this.isLoading = false;
      } catch (error) {
        this.error = 'Erreur lors du chargement des visites.';
        this.isLoading = false;
        console.error(error);
      }
    },
    updateStats() {
      const totalVisites = this.visites.length;
      const uniqueVisitors = new Set(this.visites.map(visite => visite.code_user)).size;
      const uniqueCountries = new Set(this.visites.map(visite => visite.pays)).size;

      this.stats[0].value = totalVisites;
      this.stats[0].detailedStats.total = totalVisites;

      this.stats[1].value = uniqueVisitors;
      this.stats[1].detailedStats.total = uniqueVisitors;

      this.stats[2].value = uniqueCountries;
      this.stats[2].detailedStats.total = uniqueCountries;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.fetchVisites();
  },
};
</script>

<style scoped>
.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #007bff;
}

.error {
  color: #ff0000;
}

.search-bar {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}
.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #007bff;
}

.error {
  color: #ff0000;
}

.visites {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode {
  background-color: #1a1a1a;
  color: #f5f5f5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.dark-mode h2 {
  color: #f5f5f5;
}

/* Cartes de statistiques */
.stats-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dark-mode .card {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dark-mode .card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.card-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 15px;
}

.card-content h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.dark-mode .card-content h3 {
  color: #f5f5f5;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 14px;
  color: #666;
}

.dark-mode .stat-label {
  color: #ccc;
}

/* Tableau des visites */
.visites-table {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow-x: auto;
}

.dark-mode .visites-table {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode th, .dark-mode td {
  border-bottom: 1px solid #444;
}

th {
  background-color: #007bff;
  color: white;
}

.dark-mode th {
  background-color: #005cbf;
}

tr:hover {
  background-color: #f1f1f1;
}

.dark-mode tr:hover {
  background-color: #333;
}

.action-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.dark-mode .modal-content {
  background-color: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.modal-content h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.dark-mode .modal-content h3 {
  color: #f5f5f5;
}

.close-modal {
  margin-top: 15px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-modal:hover {
  background-color: #0056b3;
}

/* Modal transition */
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  animation: fadeIn 0.5s ease forwards;
}

.card:nth-child(1) { animation-delay: 0.1s; }
.card:nth-child(2) { animation-delay: 0.2s; }
.card:nth-child(3) { animation-delay: 0.3s; }
.card:nth-child(4) { animation-delay: 0.4s; }

/* Styles pour le modal des statistiques détaillées */
.stat-modal {
  max-width: 500px;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.dark-mode .stat-item {
  background-color: #333;
}

.stat-item h4 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

.dark-mode .stat-item h4 {
  color: #f5f5f5;
}

.stat-item p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

/* Styles pour le modal des détails de la visite */
.visite-modal {
  max-width: 600px;
}

.visite-details {
  margin-bottom: 20px;
}

.visite-details p {
  margin-bottom: 10px;
}

.visite-details strong {
  font-weight: bold;
  margin-right: 5px;
}

.user-visits-table {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
}

.user-visits-table table {
  width: 100%;
  border-collapse: collapse;
}

.user-visits-table th,
.user-visits-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.dark-mode .user-visits-table th,
.dark-mode .user-visits-table td {
  border-bottom: 1px solid #444;
}

.user-visits-table th {
  background-color: #007bff;
  color: white;
}

.dark-mode .user-visits-table th {
  background-color: #005cbf;
}
</style>