<template>
    <nav class="navbar">
      <!-- Nom de l'entreprise à gauche -->
      <div class="navbar-left">
        <span class="entreprise-name">Sarezide.ci</span>
      </div>
  
      <!-- Utilisateur et bouton de déconnexion à droite -->
      <div class="navbar-right">
        <div class="user-info">
          <font-awesome-icon :icon="['fas', 'user']" class="user-icon" />
          <span class="user-name">{{ userName }}</span>
        </div>
        <button class="logout-button" @click="logout">Déconnexion</button>
      </div>
    </nav>
  </template>
  
  <script>
  import { library } from '@fortawesome/fontawesome-svg-core';
  import { faUser } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  
  library.add(faUser);
  
  export default {
    name: 'NavBar',
    components: {
      FontAwesomeIcon,
    },
    data() {
      return {
        userName: 'John Doe', // Remplacez par le nom de l'utilisateur connecté
      };
    },
    methods: {
      logout() {
        // Logique de déconnexion
        this.$router.push('/login');
      },
    },
  };
  </script>
  
  <style scoped>
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: #333;
    color: white;
    z-index: 1000;
  }
  
  .navbar-left .entreprise-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .user-icon {
    font-size: 18px;
  }
  
  .user-name {
    font-size: 16px;
  }
  
  .logout-button {
    padding: 5px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #ff1a1a;
  }
  </style>