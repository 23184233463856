<template>
    <div :class="['utilisateurs', { 'dark-mode': isDarkMode }]">
      <div class="header">
        <h2>Utilisateurs</h2>
        <button @click="toggleDarkMode" class="dark-mode-toggle">
          {{ isDarkMode ? '☀️' : '🌙' }}
        </button>
      </div>
  
      <!-- Zone de statistiques -->
      <div class="stats-container">
        <div v-for="stat in stats" :key="stat.label" class="stat-card">
          <div class="stat-icon" :style="{ backgroundColor: stat.color }">
            <i :class="stat.icon"></i>
          </div>
          <div class="stat-details">
            <h3>{{ stat.value }}</h3>
            <p>{{ stat.label }}</p>
          </div>
        </div>
      </div>
  
      <!-- Filtres -->
      <div class="filter-switch">
        <button 
          v-for="filter in filters" 
          :key="filter.value"
          :class="['filter-button', { active: currentFilter === filter.value }]"
          @click="setFilter(filter.value)"
        >
          {{ filter.label }}
        </button>
      </div>
  
      <!-- Tableau des utilisateurs -->
      <div class="utilisateurs-table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Photo</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Ville</th>
              <th>Date d'inscription</th>
              <th>Statut</th>
              <th>Contact</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" :key="user.id_user">
              <td>{{ user.id_user }}</td>
              <td>
                <img :src="user.photo || 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png?20200919003010'" :alt="user.nom" class="user-avatar">
              </td>
              <td>{{ user.nom }} {{ user.prenom }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.ville }}</td>
              <td>{{ formatDate(user.dte_inscription) }}</td>
              <td>
                <span :class="['status-badge', getStatusClass(user.statut)]">
                  {{ getStatusLabel(user.statut) }}
                </span>
              </td>
              <td>{{ user.contact }}</td>
              <td>{{ user.agent === 'OK' ? 'Agent' : 'Utilisateur' }}</td>
              <td>
                <button class="action-button" @click="voirUtilisateur(user)">Voir</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Modal pour les détails de l'utilisateur -->
      <transition name="modal">
        <div v-if="showUserModal" class="modal-overlay" @click="closeModal">
          <div class="modal-content user-modal" @click.stop>
            <h3>Détails de l'utilisateur</h3>
            <div v-if="selectedUser" class="user-details">
              <img :src="selectedUser.photo || '/default-avatar.png'" :alt="selectedUser.nom" class="user-avatar-large">
              <div class="user-info">
                <p><strong>Nom:</strong> {{ selectedUser.nom }} {{ selectedUser.prenom }}</p>
                <p><strong>Email:</strong> {{ selectedUser.email }}</p>
                <p><strong>Ville:</strong> {{ selectedUser.ville }}</p>
                <p><strong>Contact:</strong> {{ selectedUser.contact }}</p>
                <p><strong>Type:</strong> {{ selectedUser.agent === 'OK' ? 'Agent' : 'Utilisateur' }}</p>
                <p><strong>Date d'inscription:</strong> {{ formatDate(selectedUser.dte_inscription) }}</p>
                <p><strong>Statut:</strong> 
                  <span :class="['status-badge', getStatusClass(selectedUser.statut)]">
                    {{ getStatusLabel(selectedUser.statut) }}
                  </span>
                </p>
              </div>
            </div>
            <div class="action-buttons">
              <button 
                class="action-button activate" 
                @click="updateUserStatus('OK')" 
                :disabled="selectedUser && selectedUser.statut === 'OK'"
              >
                Activer
              </button>
              <button 
                class="action-button warning" 
                @click="updateUserStatus('2')" 
                :disabled="selectedUser && selectedUser.statut === '2'"
              >
                Mettre en attente
              </button>
              <button 
                class="action-button deactivate" 
                @click="updateUserStatus('KO')" 
                :disabled="selectedUser && selectedUser.statut === 'KO'"
              >
                Bloquer
              </button>
            </div>
            <button @click="closeModal" class="close-modal">Fermer</button>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'UtilisaTeurs',
    data() {
      return {
        isDarkMode: false,
        showUserModal: false,
        selectedUser: null,
        currentFilter: 'tous',
        filters: [
          { label: 'Tous', value: 'tous' },
          { label: 'Actifs', value: 'actif' },
          { label: 'En attente', value: 'en-attente' },
          { label: 'Bloqués', value: 'bloque' }
        ],
        stats: [
          { label: 'Total Utilisateurs', value: 0, icon: 'fas fa-users', color: '#007bff' },
          { label: 'Utilisateurs Actifs', value: 0, icon: 'fas fa-user-check', color: '#28a745' },
          { label: 'En Attente', value: 0, icon: 'fas fa-user-clock', color: '#ffc107' },
          { label: 'Utilisateurs Bloqués', value: 0, icon: 'fas fa-user-slash', color: '#dc3545' }
        ],
        users: []
      };
    },
    computed: {
      filteredUsers() {
        switch(this.currentFilter) {
          case 'actif':
            return this.users.filter(user => user.statut === 'OK');
          case 'en-attente':
            return this.users.filter(user => user.statut === '2');
          case 'bloque':
            return this.users.filter(user => user.statut === 'KO');
          default:
            return this.users;
        }
      }
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await axios.get('https://korezide.com/appi_web/admin_users.php');
          if (response.data.status === 'success') {
            this.users = response.data.data;
            this.updateStats();
          } else {
            console.error('Erreur:', response.data.message);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
      },
  
      async fetchUserById(id) {
        try {
          const response = await axios.get(`https://korezide.com/appi_web/admin_users.php?id=${id}`);
          if (response.data.status === 'success') {
            this.selectedUser = response.data.data;
            this.showUserModal = true;
          } else {
            console.error('Erreur:', response.data.message);
            alert('Utilisateur non trouvé');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'utilisateur:', error);
          alert('Erreur lors de la récupération des données');
        }
      },
  
      async updateUserStatus(newStatus) {
        if (!this.selectedUser) return;
  
        try {
          const response = await axios.post('https://korezide.com/appi_web/admin_users.php', {
            id_user: this.selectedUser.id_user,
            statut: newStatus
          });
  
          if (response.data.status === 'success') {
            const userIndex = this.users.findIndex(u => u.id_user === this.selectedUser.id_user);
            if (userIndex !== -1) {
              this.users[userIndex].statut = newStatus;
              this.selectedUser.statut = newStatus;
            }
  
            this.updateStats();
            alert('Statut mis à jour avec succès');
            
            // Rafraîchir les données
            await this.fetchUserById(this.selectedUser.id_user);
          } else {
            throw new Error(response.data.message || 'Erreur lors de la mise à jour');
          }
        } catch (error) {
          console.error('Erreur lors de la mise à jour du statut:', error);
          alert('Erreur lors de la mise à jour du statut');
        }
      },
  
      updateStats() {
        this.stats[0].value = this.users.length;
        this.stats[1].value = this.users.filter(user => user.statut === 'OK').length;
        this.stats[2].value = this.users.filter(user => user.statut === '2').length;
        this.stats[3].value = this.users.filter(user => user.statut === 'KO').length;
      },
  
      getStatusClass(statut) {
        switch(statut) {
          case 'OK': return 'actif';
          case '2': return 'en-attente';
          case 'KO': return 'bloque';
          default: return '';
        }
      },
  
      getStatusLabel(statut) {
        switch(statut) {
          case 'OK': return 'Actif';
          case '2': return 'En attente';
          case 'KO': return 'Bloqué';
          default: return 'Inconnu';
        }
      },
  
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
      },
  
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;
      },
  
      setFilter(filter) {
        this.currentFilter = filter;
      },
  
      async voirUtilisateur(user) {
        await this.fetchUserById(user.id_user);
      },
  
      closeModal() {
        this.showUserModal = false;
        this.selectedUser = null;
      }
    },
    mounted() {
      this.fetchUsers();
    }
  };
  </script>
  
  <style scoped>
  .utilisateurs {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode {
    background-color: #1a1a1a;
    color: #f5f5f5;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dark-mode-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .dark-mode-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .dark-mode h2 {
    color: #f5f5f5;
  }
  
  .stats-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .stat-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    width: calc(25% - 15px);
  }
  
  .dark-mode .stat-card {
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .stat-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  
  .stat-icon i {
    color: white;
    font-size: 24px;
  }
  
  .stat-details {
    flex-grow: 1;
  }
  
  .stat-details h3 {
    font-size: 24px;
    margin: 0;
    color: #333;
  }
  
  .dark-mode .stat-details h3 {
    color: #f5f5f5;
  }
  
  .stat-details p {
    margin: 5px 0 0;
    color: #666;
    font-size: 14px;
  }
  
  .dark-mode .stat-details p {
    color: #aaa;
  }
  
  .filter-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #e0e0e0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode .filter-button {
    background-color: #333;
    color: #f5f5f5;
  }
  
  .filter-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .dark-mode .filter-button.active {
    background-color: #0056b3;
  }
  
  .utilisateurs-table {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow-x: auto;
  }
  
  .dark-mode .utilisateurs-table {
    background: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .dark-mode th, .dark-mode td {
    border-bottom: 1px solid #444;
  }
  
  th {
    background-color: #007bff;
    color: white;
  }
  
  .dark-mode th {
    background-color: #005cbf;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  
  .dark-mode tr:hover {
    background-color: #333;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .status-badge {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  
  .status-badge.actif {
    background-color: #28a745;
    color: white;
  }
  
  .status-badge.inactif {
    background-color: #dc3545;
    color: white;
  }
  
  .action-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  
  .action-button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    transition: background-color 0.3s ease;
  }
  
  .dark-mode .modal-content {
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .dark-mode .modal-content h3 {
    color: #f5f5f5;
  }
  
  .user-details {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .user-avatar-large {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .user-info {
    flex-grow: 1;
  }
  
  .user-info p {
    margin-bottom: 10px;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .action-buttons .action-button {
    margin: 0 10px;
  }
  
  .action-button.activate {
    background-color: #28a745;
  }
  
  .action-button.activate:hover {
    background-color: #218838;
  }
  
  .action-button.deactivate {
    background-color: #dc3545;
  }
  
  .action-button.deactivate:hover {
    background-color: #c82333;
  }
  
  .close-modal {
    margin-top: 15px;
    padding: 8px 15px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .close-modal:hover {
    background-color: #5a6268;
  }
  
  /* Modal transition */
  .modal-enter-active, .modal-leave-active {
    transition: opacity 0.3s;
  }
  
  .modal-enter, .modal-leave-to {
    opacity: 0;
  }

  .status-badge.actif {
  background-color: #28a745;
  color: white;
}

.status-badge.bloque {
  background-color: #dc3545;
  color: white;
}

.status-badge.en-attente {
  background-color: #ffc107;
  color: black;
}

.action-button.warning {
  background-color: #ffc107;
  color: #000;
}

.action-button.warning:hover {
  background-color: #e0a800;
}
  </style>