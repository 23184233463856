<template>
  <div id="app">
    <!-- Afficher la Navbar et la Sidebar sauf sur la page de login -->
    <Navbar v-if="showNavbar" />
    <div class="main-container" v-if="showSidebar">
      <Sidebar />
      <div class="content">
        <router-view />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import Navbar from './components/navbar.vue';
import Sidebar from './components/sidebar.vue';

export default {
  components: {
    Navbar,
    Sidebar,
  },
  computed: {
    showNavbar() {
      return this.$route.path !== '/login';
    },
    showSidebar() {
      return this.$route.path !== '/login';
    },
  },
};
</script>

<style>
/* Styles globaux */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  display: flex;
  margin-top: 60px; /* Hauteur de la Navbar */
}

.content {
  flex: 1;
  padding: 20px;
  margin-left: 250px; /* Largeur de la Sidebar */
}
</style>