<template>
  <div :class="['reservations', { 'dark-mode': isDarkMode }]">
    <div class="header">
      <h2>Réservations</h2>
      <button @click="toggleDarkMode" class="dark-mode-toggle">
        {{ isDarkMode ? '☀️' : '🌙' }}
      </button>
    </div>

    <!-- Cartes de statistiques -->
    <div class="stats-cards">
      <div 
        class="card" 
        v-for="(stat, index) in stats" 
        :key="index" 
        :class="`card-${index + 1}`"
        @click="openStatModal(stat)"
      >
        <div class="card-icon">
          <font-awesome-icon :icon="stat.icon" />
        </div>
        <div class="card-content">
          <h3>{{ stat.title }}</h3>
          <p class="stat-value">{{ stat.value }}</p>
          <p class="stat-label">{{ stat.label }}</p>
        </div>
      </div>
    </div>

    <!-- Switch pour les types de réservations -->
    <div class="reservation-switch">
      <button 
        :class="['switch-button', { active: activeReservationType === 'encours' }]" 
        @click="activeReservationType = 'encours'"
      >
        En cours
      </button>
      <button 
        :class="['switch-button', { active: activeReservationType === 'valide' }]" 
        @click="activeReservationType = 'valide'"
      >
        Validé
      </button>
      <button 
        :class="['switch-button', { active: activeReservationType === 'annule' }]" 
        @click="activeReservationType = 'annule'"
      >
        Annulé
      </button>
    </div>

    <!-- Tableau des réservations -->
    <div class="reservations-table">
      <table>
        <thead>
          <tr>
            <th>N°</th>
            <th>Ref réservation</th>
            <th>Période</th>
            <th>Nb jours</th>
            <th>Coût unitaire</th>
            <th>Coût total</th>
            <th>Client</th>
            <th>Propriétaire</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reservation, index) in filteredReservations" :key="reservation.id">
            <td>{{ index + 1 }}</td>
            <td>
              <a href="#" @click.prevent="openResidenceModal(reservation)">
                {{ reservation.reference }}
              </a>
            </td>
            <td>{{ reservation.periode }}</td>
            <td>{{ reservation.nbJours }}</td>
            <td>{{ reservation.coutUnitaire }}</td>
            <td>{{ reservation.coutTotal }}</td>
            <td>
              <a href="#" @click.prevent="openPersonModal(reservation.client)">
                {{ reservation.client.nom }}
              </a>
            </td>
            <td>
              <a href="#" @click.prevent="openPersonModal(reservation.proprietaire)">
                {{ reservation.proprietaire.nom }}
              </a>
            </td>
            <td>
              <template v-if="activeReservationType === 'encours'">
                <button class="action-button" @click="validerReservation(reservation)">Valider</button>
                <button class="action-button cancel" @click="annulerReservation(reservation)">Annuler</button>
              </template>
              <template v-if="activeReservationType === 'valide'">
                <button class="action-button cancel" @click="annulerReservation(reservation)">Annuler</button>
              </template>
              <template v-if="activeReservationType === 'annule'">
                <button class="action-button" @click="voirReservation(reservation)">Voir</button>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal pour les statistiques détaillées -->
    <transition name="modal">
      <div v-if="showStatModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content stat-modal" @click.stop>
          <h3>{{ selectedStat.title }} - Statistiques détaillées</h3>
          <div class="stat-grid">
            <div class="stat-item">
              <h4>Total</h4>
              <p>{{ selectedStat.detailedStats.total }}</p>
            </div>
            <div class="stat-item">
              <h4>Ce mois</h4>
              <p>{{ selectedStat.detailedStats.month }}</p>
            </div>
            <div class="stat-item">
              <h4>Cette semaine</h4>
              <p>{{ selectedStat.detailedStats.week }}</p>
            </div>
            <div class="stat-item">
              <h4>Aujourd'hui</h4>
              <p>{{ selectedStat.detailedStats.day }}</p>
            </div>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>

    <!-- Modal pour la webview de la résidence -->
    <transition name="modal">
      <div v-if="showResidenceModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content residence-modal" @click.stop>
          <h3>Détails de la résidence</h3>
          <iframe :src="selectedResidence.url" class="residence-webview"></iframe>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>

    <!-- Modal pour les détails du client ou du propriétaire -->
    <transition name="modal">
      <div v-if="showPersonModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content person-modal" @click.stop>
          <div class="person-details">
            <img :src="selectedPerson.photo" alt="Photo de profil" class="profile-photo">
            <h3>{{ selectedPerson.nom }}</h3>
            <p><strong>Email:</strong> {{ selectedPerson.email }}</p>
            <p><strong>Téléphone 1:</strong> {{ selectedPerson.telephone1 }}</p>
            <p><strong>Téléphone 2:</strong> {{ selectedPerson.telephone2 }}</p>
          </div>
          <button @click="closeModal" class="close-modal">Fermer</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarCheck, faCalendarTimes, faCalendarAlt, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faCalendarCheck, faCalendarTimes, faCalendarAlt, faCalendarDay);

export default {
  name: 'ReserVations',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      isDarkMode: false,
      showStatModal: false,
      showResidenceModal: false,
      showPersonModal: false,
      selectedStat: null,
      selectedResidence: null,
      selectedPerson: null,
      activeReservationType: 'encours',
      stats: [
        { 
          title: 'Réservations en cours', 
          value: '45', 
          label: 'Total', 
          icon: ['fas', 'calendar-alt'],
          detailedStats: {
            total: '45',
            month: '15',
            week: '8',
            day: '3'
          }
        },
        { 
          title: 'Réservations validées', 
          value: '128', 
          label: 'Total', 
          icon: ['fas', 'calendar-check'],
          detailedStats: {
            total: '128',
            month: '42',
            week: '12',
            day: '5'
          }
        },
        { 
          title: 'Réservations annulées', 
          value: '23', 
          label: 'Total', 
          icon: ['fas', 'calendar-times'],
          detailedStats: {
            total: '23',
            month: '8',
            week: '3',
            day: '1'
          }
        },
        { 
          title: 'Réservations totales', 
          value: '196', 
          label: 'Total', 
          icon: ['fas', 'calendar-day'],
          detailedStats: {
            total: '196',
            month: '65',
            week: '23',
            day: '9'
          }
        },
      ],
      reservations: [
        { 
          id: 1, 
          reference: 'RES001', 
          periode: '01/05/2023 - 07/05/2023', 
          nbJours: 7,
          coutUnitaire: '50000 FCFA', 
          coutTotal: '350000 FCFA', 
          client: {
            nom: 'Alice Johnson',
            email: 'alice@example.com',
            telephone1: '+1234567890',
            telephone2: '+0987654321',
            photo: 'https://example.com/alice-photo.jpg'
          },
          proprietaire: {
            nom: 'John Doe',
            email: 'john@example.com',
            telephone1: '+2345678901',
            telephone2: '+1098765432',
            photo: 'https://example.com/john-photo.jpg'
          },
          status: 'encours', 
          url: 'https://example.com/residence/1' 
        },
        { 
          id: 2, 
          reference: 'RES002', 
          periode: '10/05/2023 - 15/05/2023', 
          nbJours: 6,
          coutUnitaire: '45000 FCFA', 
          coutTotal: '270000 FCFA', 
          client: {
            nom: 'Bob Smith',
            email: 'bob@example.com',
            telephone1: '+3456789012',
            telephone2: '+2109876543',
            photo: 'https://example.com/bob-photo.jpg'
          },
          proprietaire: {
            nom: 'Jane Doe',
            email: 'jane@example.com',
            telephone1: '+4567890123',
            telephone2: '+3210987654',
            photo: 'https://example.com/jane-photo.jpg'
          },
          status: 'valide', 
          url: 'https://example.com/residence/2' 
        },
        { 
          id: 3, 
          reference: 'RES003', 
          periode: '20/05/2023 - 25/05/2023', 
          nbJours: 6,
          coutUnitaire: '55000 FCFA', 
          coutTotal: '330000 FCFA', 
          client: {
            nom: 'Charlie Brown',
            email: 'charlie@example.com',
            telephone1: '+5678901234',
            telephone2: '+4321098765',
            photo: 'https://example.com/charlie-photo.jpg'
          },
          proprietaire: {
            nom: 'Emma Wilson',
            email: 'emma@example.com',
            telephone1: '+6789012345',
            telephone2: '+5432109876',
            photo: 'https://example.com/emma-photo.jpg'
          },
          status: 'annule', 
          url: 'https://example.com/residence/3' 
        },
      ],
    };
  },
  computed: {
    filteredReservations() {
      return this.reservations.filter(reservation => reservation.status === this.activeReservationType);
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    openStatModal(stat) {
      this.selectedStat = stat;
      this.showStatModal = true;
    },
    openResidenceModal(reservation) {
      this.selectedResidence = reservation;
      this.showResidenceModal = true;
    },
    openPersonModal(person) {
      this.selectedPerson = person;
      this.showPersonModal = true;
    },
    closeModal() {
      this.showStatModal = false;
      this.showResidenceModal = false;
      this.showPersonModal = false;
      this.selectedStat = null;
      this.selectedResidence = null;
      this.selectedPerson = null;
    },
    validerReservation(reservation) {
      // Logique pour valider la réservation
      console.log('Valider réservation:', reservation);
    },
    annulerReservation(reservation) {
      // Logique pour annuler la réservation
      console.log('Annuler réservation:', reservation);
    },
    voirReservation(reservation) {
      // Logique pour voir les détails de la réservation
      console.log('Voir réservation:', reservation);
    },
  },
};
</script>

<style scoped>
.reservations {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode {
  background-color: #1a1a1a;
  color: #f5f5f5;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.dark-mode h2 {
  color: #f5f5f5;
}

/* Cartes de statistiques */
.stats-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dark-mode .card {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.dark-mode .card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

.card-icon {
  font-size: 24px;
  color: #007bff;
  margin-right: 15px;
}

.card-content h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.dark-mode .card-content h3 {
  color: #f5f5f5;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.stat-label {
  font-size: 14px;
  color: #666;
}

.dark-mode .stat-label {
  color: #ccc;
}

/* Switch pour les types de réservations */
.reservation-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.switch-button {
  padding: 10px 20px;
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode .switch-button {
  background-color: #333;
  color: #f5f5f5;
}

.switch-button.active {
  background-color: #007bff;
  color: white;
}

.dark-mode .switch-button.active {
  background-color: #005cbf;
}

/* Tableau des réservations */
.reservations-table {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.dark-mode .reservations-table {
  background: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode th, .dark-mode td {
  border-bottom: 1px solid #444;
}

th {
  background-color: #007bff;
  color: white;
}

.dark-mode th {
  background-color: #005cbf;
}

tr:hover {
  background-color: #f1f1f1;
}

.dark-mode tr:hover {
  background-color: #333;
}

.action-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 5px;
}

.action-button:hover {
  background-color: #0056b3;
}

.action-button.cancel {
  background-color: #dc3545;
}

.action-button.cancel:hover {
  background-color: #c82333;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.dark-mode .modal-content {
  background-color: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.modal-content h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.dark-mode .modal-content h3 {
  color: #f5f5f5;
}

.close-modal {
  margin-top: 15px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-modal:hover {
  background-color: #0056b3;
}

/* Modal transition */
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  animation: fadeIn 0.5s ease forwards;
}

.card:nth-child(1) { animation-delay: 0.1s; }
.card:nth-child(2) { animation-delay: 0.2s; }
.card:nth-child(3) { animation-delay: 0.3s; }
.card:nth-child(4) { animation-delay: 0.4s; }

/* Styles pour le modal des statistiques détaillées */
.stat-modal {
  max-width: 500px;
}

.stat-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.dark-mode .stat-item {
  background-color: #333;
}

.stat-item h4 {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

.dark-mode .stat-item h4 {
  color: #f5f5f5;
}

.stat-item p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

/* Styles pour la webview de la résidence */
.residence-modal {
  max-width: 800px;
  width: 90%;
}

.residence-webview {
  width: 100%;
  height: 500px;
  border: none;
  border-radius: 8px;
  margin-bottom: 15px;
}
</style>